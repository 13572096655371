.events{
    height: 600px;
    padding: 67px 0px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.events-title{
    margin-left: 10px;
    font-weight: 600;
    font-size: 15px;
    line-height: 0px;
    text-align: left;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #343379;

}

.event-table{
    list-style: none;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: bolder;
    margin-left: -45px;
    font-size: 32px;
    line-height: 50px;
    letter-spacing: 0.04em;
    color: rgba(1, 0, 87, 0.8);
}

.event-list:hover {
    color: #CEEF49;
    cursor: pointer;
  }

  .swiper {
    width: 1240px;
    height: 617px;
    display: flex;
    margin-top: -100px;
    position: relative;
    /* z-index: -1 !important; */
  }

  
  .swiper-slide {
    /* margin-left: 0px; */
    height: 300px;
    background-image: url("../../assets/img/background.jpg");
    background-repeat: no-repeat;
    background-position: center;
    mix-blend-mode: normal;
    backdrop-filter: blur(4px);
    line-height: 300px;
    text-align: center;
    position: relative;

  }
 
  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative ;
    transition-property: transform;
 
  
}

   .thinktech-event{
    font-family: 'Epilogue';
    font-weight: 900;
    font-size: 23px;
    color: white;
    /* z-index: -1 !important; */
   }

   .thinktech-description{
    opacity: 0.7;
    text-transform: lowercase;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    display: flex;
    text-align: left;
    margin-left: 1rem;
   }

   
   .thinktech-description-uxui{
    opacity: 0.7;
    text-transform: lowercase;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    display: flex;
    text-align: left;
    margin-left: 1rem;
    margin-top: 59px;
   }

   .event-more-informations{
    display: flex;
		background-image: url(../../assets/img/event-informations.svg) ;
		background-repeat: no-repeat;
		background-position: 16rem; 
    font-weight: lighter;
    opacity: 0.8;
		height: 1rem; 
		padding-left: 1rem;
		padding-bottom: 3rem;
		padding-top: 2rem;
		font-size: 13px;
		line-height: 30px;
		color: white;
   }

   .event-location-editions{
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #FFFFFF;
    margin-left: 3rem;
    padding-top: 0.5rem;
    opacity: 0.7;
    display: flex;
}

.event-location-icon {
  background-image: url(../../assets/img/Pin1.svg);
      background-repeat: no-repeat;
      height: 2rem;
      margin-left: 1rem;
  
}

.event-date-editions{
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    margin-left: 3rem;
    margin-top: 10px;
    padding-top: 0.5rem;
    width: 100%;
    opacity: 0.7;
    display: flex;
}

.event-date-icon{
    background-image: url(../../assets/img/Calendar2.svg);
    background-repeat: no-repeat;
    margin-top: 2px;
    margin-left: 1rem;
    height: 2rem;
}

.events-title-card{
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 60px;
  display: flex;
  text-align: left;
  margin-top: 7rem;
  margin-left: 1rem;
}

.events-title-card-uxui{
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 60px;
  display: flex;
  text-align: left;
  margin-top: 10rem;
  margin-left: 1rem;
}

.event-wrapper{
  margin: 10px 40px 10px 34px;
}

.event-thinktech{
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  font-weight: bolder;
  font-size: 30px;
  line-height: 40px;
  display: flex;
  align-items: center;
  letter-spacing: 0.12em;
  color:#010057;
}

.event-thinktech-active{
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  font-weight: bolder;
  font-size: 30px;
  line-height: 40px;
  display: flex;
  align-items: center;
  letter-spacing: 0.12em;
  color:#CEEF49;
}


.swiper-pagination {
        bottom: 5px !important;
        padding-top:5px;
        background-color: white;
}

.swiper-wrapper{
  height: 97% !important;
}


@media only screen and (min-width:320px) and (max-width: 480px) {
  .events{
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 821px;
    padding: -1px 198px;
}

.events-title{
    margin-left: 15px;
    font-weight: 600;
    font-size: 15px;
    line-height: 0px;
    text-align: left;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #343379;

}

.event-table{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 134px;
    margin-right: 95px;
}

.event-list:hover {
    color: #CEEF49;
    cursor: pointer;
  }

  .swiper {
    width: 1240px;
    height: 617px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -78px;
    

  }
  .swiper-slide {
    margin-left: 10px;
    height: 300px;
    background-image: url("../../assets/img/background.jpg");
    background-repeat: no-repeat;
    background-position: center;
    mix-blend-mode: normal;
    backdrop-filter: blur(4px);
    line-height: 300px;
    text-align: center;
  }
 

   .thinktech-event{
    font-family: 'Epilogue';
    font-weight: 900;
    font-size: 23px;
    color: white;
    margin: 0px 0px 0px 0px;
    margin-left: 0px;
    padding: 0px 0px 0px 0px;
   }

   .thinktech-description{
    opacity: 0.7;
    text-transform: lowercase;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    display: flex;
    text-align: left;
    margin-left: 1rem;
   }


   .event-more-informations{
    display: flex;
		background-image: url(../../assets/img/event-informations.svg) ;
		background-repeat: no-repeat;
		background-position: 16rem; 
    font-weight: lighter;
    opacity: 0.8;
		height: 1rem; 
		padding-left: 1rem;
		padding-bottom: 3rem;
		padding-top: 2rem;
		font-size: 13px;
		line-height: 30px;
		color: white;
   }

   .event-location-editions{
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    margin-left: 3rem;
    padding-top: 0.5rem;
    opacity: 0.7;
    display: flex;
}

.event-location-icon {
  background-image: url(../../assets/img/Pin1.svg);
      background-repeat: no-repeat;
      height: 2rem;
      margin-left: 1rem;
  
}

.event-date-editions{
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    margin-left: 3rem;
    margin-top: 10px;
    padding-top: 0.5rem;
    width: 100%;
    opacity: 0.7;
    display: flex;
}

.event-date-icon{
    background-image: url(../../assets/img/Calendar2.svg);
    background-repeat: no-repeat;
    margin-top: 2px;
    margin-left: 1rem;
    height: 2rem;
}

.events-title-card{
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 60px;
  display: flex;
  text-align: left;
  margin-top: 8rem;
  margin-left: 1rem;
}

.event-wrapper{
  margin: 10px 40px 10px 34px;
}

.event-thinktech{
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  font-weight: bolder;
  font-size: 28px;
  line-height: 40px;
  display: flex;
  align-items: center;
  letter-spacing: 0.12em;
  color:#010057;
}

.event-thinktech-active{
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  font-weight: bolder;
  font-size: 30px;
  line-height: 40px;
  display: flex;
  align-items: center;
  letter-spacing: 0.12em;
  color: #CEEF49;
}
}




@media only screen and (min-width: 481px) and (max-width: 820px) {

  .swiper {
    width: 1674px;
    height: 617px;
    display: flex;
    margin-top: -100px;
    position: relative;
  
}

.event-table {
  margin-left: -83px;
    margin-right: 50px;
}

.events {
  overflow: hidden;

  height: 696px;
  padding: 70px 108px;
}
}

@media only screen and (min-width: 821px) and (max-width: 1024px) {
  
  .swiper {
    width: 1588px;
    height: 617px;
    display: flex;
    margin-top: -100px;
    position: relative;
  
}

.event-table {
  margin-left: -127px;
  margin-right: 0px;
}

.events {
  overflow: hidden;

  height: 696px;
  padding: 70px 108px;
}
}

@media only screen and (min-width: 1025px) and (max-width: 1200px){
  .events {
    overflow: hidden;
  
    height: 696px;
    padding: 70px 108px;
  }

  .event-table {
    margin-left: -101px;
    
}
}

@media only screen and (min-width: 1201px) and (max-width: 1727px){
  .events {
    overflow: hidden;

    height: 696px;
    padding: 70px 108px;
  }
}

@media only screen and (min-width: 1728px)  and (max-width:1920px)  {
  .event-table {
   
    margin-left: 99px;
    margin-right: 37px;
}

.event-thinktech {
  font-size: 35px;
  line-height: 50px;
}

.events-title {
  font-size: 14px;
}
.event-thinktech-active{
  font-size: 35px;
}
}
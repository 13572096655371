.partners {
    background-color: #010057;
    height: 135px;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
     
    
}

.partners-title{
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    margin-left: 145px;
    line-height: 33px;
    color: #FFFFFF;
}

.companies{
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-right: 71px;
}


@media only screen and (max-width: 480px){

    .partners {
        background-color: #010057;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        flex-direction: column;
        padding: 54px;
    }
    
    .partners-title{
        margin-left: 0px;
        line-height: 22px;
        margin-bottom: 40px;
        margin-top: 13px;
    }
    
    .companies{
        align-items: center;
        flex-grow: 1;
        justify-content: space-around;
        display: flex;
        flex-wrap: wrap;
        margin-right: 0px;
    }

    .image-container{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2%;
        margin: 2%;
        flex: 1 0 40%;
        border: 1px solid #767676;
        min-height: 70px;
    }

    .image-container>img{
        max-width: 50%;
        object-fit: contain;
    }
    
}


@media only screen and (min-width: 481px) and (max-width: 820px) {
    .partners {
        background-color: #010057;
        height: 135px;
        display: flex;
        align-items: center;
        font-size: 1.2rem;
    }
    .partners-title {
        margin-left: 15px;  
        font-size: 19px;
    }

    .companies {
        padding: 0px 0px 0px 9px;
    }
}

@media only screen and (min-width: 821px) and (max-width: 1024px) {
    .partners-title {
        margin-left: 35px;
    }
}

.mainbox {
  margin: 10rem 10rem 10rem 38.5rem;
  height: 600px;
  width: 600px;
  position: relative;
}

  .err {
    color: #010057;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position:absolute;
    left: 20%;
    top: 8%;
  }

.far {
  position: absolute;
  font-size: 8.5rem;
  left: 42%;
  top: 15%;
  color: #010057;
}

 .err2 {
    color: #010057;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position:absolute;
    left: 68%;
    top: 8%;
  }

.msg {

    color: #010057;
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.6rem;
    position:absolute;
    left: 16%;
    top: 45%;
    width: 75%;
    color: #010057;

  }

.home-redirect {
  text-decoration: none;
  color: #010057;

}

.home-redirect:hover {
  text-decoration: underline;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .mainbox {
    margin: 176px 0px 0px -101px;
    height: 600px;
    width: 600px;
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
  
}

@media only screen and (min-width: 481px) and (max-width: 820px) {
  .mainbox {
    margin: 176px 0px 0px -62px;
    height: 600px;
    width: 600px;
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
  
}


@media only screen and (max-width: 375px){
  .mainbox {
    margin: 176px 0px 0px -62px;
    height: 600px;
    width: 600px;
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
}



@media only screen and (min-width: 821px) and (max-width: 1024px) {
  .mainbox {
    margin: 10rem 10rem 10rem 12.5rem;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .mainbox {
    margin: 10rem 10rem 10rem 15.5rem;
  }
  }
  
  @media only screen and (min-width: 1201px) and (max-width: 1727px) {
    .mainbox {
      margin: 19rem 0rem 7rem 35.5rem;
  }
  }
  
  
  @media only screen and (min-width: 1728px)  and (max-width:1920px) {
   
  
  
  }
.coming-soon-homepage{
    background-color: #010057;
    position: relative;
    color: white;
    padding: 45px 239px;
}


.agenda-wrapper{
    font-family: 'Epilogue';
    font-style: normal;
    /* display: grid; */
    /* grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px; */
}

.event-agenda-title{
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    margin-left: 10px;
    margin-top: 74px;
}

.event-agenda-t{
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 35px;
    margin-left: 10px;
}

.sections-event{
    display: flex;
    /* align-items: baseline; */
    flex-direction: row;
    justify-content: space-between;
    /* justify-content: space-around; */
    margin-bottom: 25px;
    margin-right: 713px;
}

.event-agenda-details{
    display: flex;
    align-items: center;
    letter-spacing: 0.12em;
    color: #FFFFFF;
    opacity: 0.8;
    margin-left: 10px;
}

.event-agenda-details-second{
    display: flex;
    align-items: center;
    letter-spacing: 0.12em;
    color: #FFFFFF;
    opacity: 0.8;
    margin-left: 10px;
}



.event-agenda{
    margin-bottom: 40px;
    grid-area: 1 / 1 / 2 / 2;
}

.first-section-event{
    grid-area: 2 / 1 / 3 / 2; 
}

.first-section-title{
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: center;
    letter-spacing: 0.12em;
    color: rgba(227, 255, 117, 0.7);
}

.first-section-title-active{
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: center;
    letter-spacing: 0.12em;
    color: #CEEF49;
}

.second-section-event{
    grid-area: 2 / 2 / 3 / 3;
}

.second-section-title{
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: center;
    letter-spacing: 0.12em;
    color: rgba(227, 255, 117, 0.7);
}

.second-section-title-active{
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: center;
    letter-spacing: 0.12em;
    color:#CEEF49 ;
}

.key-speakers-title{
    font-weight: 900;
    font-size: 29px;
    line-height: 35px;
    display: flex;
    align-items: center;
    letter-spacing: 0.12em;
    color: #00AEEF;
}

.border{
    height: 1px;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    display: block;
    position: relative;
    top: 1px;
    width: 100%;
}

/* .key-speakers-first{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    
} */


.panel-speakers{
    font-weight: bolder;
    color: #00AEEF;
}

.event-content{
    line-height: 25px;
    text-align: justify;
    padding: 13px 10px 13px 56px;
}



.event-closing-details{
    display: flex;
    justify-content: center;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    opacity: 0.8;
}

.event-closing-activities{
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    font-weight: bold;

}

.details-wrapper-coming-soon{
    display:  -webkit-inline-box;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    opacity: 0.7;
}

.accordion{
    padding-bottom: 1rem;
    display: grid;
}

.accordion-row{
    display: flex;
    flex-direction: column;
 
}

.left-content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}


.left-content .Collapsible{
    cursor: pointer;
    font-size: 15px;
    line-height: 28px;
    color: white;
}

.fa-angle-down{

    width: 2%;
    padding-top: 10px;
    float: right;
}

.fa-angle-up{
    width: 2%;
    padding-top: 20px;
    float: right;
}

.content-show{
    display: flex;
    justify-content: space-between;
    padding: 30px 50px;
    font-size: 15px;
    align-items: center;
}

.panel-img{
    width: 300px;
    object-fit: contain;
    margin-left: 40px;
    border-radius: 35px;
}




@media only screen and (min-width:320px) and (max-width: 480px){

    .sections-event{
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: center; */
        margin-right: 0px;
    }

    .accordion{
        padding-bottom: 1rem;
        display: grid;
    }
    
    .accordion-row{
        display: flex;
        flex-direction: column;
     
    }
    
    .left-content{
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    }
    
    
    .left-content .Collapsible{
        cursor: pointer;
        font-size: 15px;
        line-height: 28px;
        color: white;
    }
    
    .fa-angle-down{
    
        width: 8%;
        padding-top: 10px;
        float: right;
    }
    
    .fa-angle-up{
        width: 8%;
        padding-top: 20px;
        float: right;
    }
    
    .content-show{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px 0px;
        font-size: 15px;
        align-items: center;
    }
    
    .panel-img{
        width: 250px;
        object-fit: contain;
        margin-left: 0px;
        margin-bottom: 10px;
    }
    
    .panel-title{ 
        text-align: center;
    }
    

    .coming-soon-homepage{
        align-items: center;
        flex-direction: column;
        padding: 0px 22px;
        display: flex;
        justify-content: center;
          height: 90%;
        background-color: #010057;
        color: white;
      
    }
    
    .details-wrapper-coming-soon{
        margin-right: 195px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;  
    }
    
    .agenda-wrapper{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-family: 'Epilogue';
        font-style: normal;
        /* display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px; */
    }
    
    .event-agenda-title{
        width: 100%;
        font-size: 30px;
        line-height: 35px;
      
    }

    .event-agenda-details-second {
        display: flex;
        align-items: center;
        letter-spacing: 0.12em;
        color: #FFFFFF;
        width: 100%;
        opacity: 0.8;
        /* margin-left: 0px; */
    }
    
    
    .event-agenda-details{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width:max-content;
        
    }
    
    
    
    .event-agenda{
        /* margin-left: -9px; */
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 39px;
        grid-area: 1 / 1 / 2 / 2;
    }
    
    .first-section-event{
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 26px;
        grid-area: 1 / 1 / 3 / 2; 
    }
    
    .first-section-title{
        width: 358px;
        /* margin-left: -9px; */
        font-size: 28px;
        display: flex;
        align-items: center;
    }
    
    .first-section-title-active{
        /* margin-left: -9px; */
        font-size: 28px;
        width: 358px;
        display: flex;
        align-items: center;
        color: #CEEF49;
    }
    
    .second-section-event{
        display: flex;
        margin-bottom: 29px;
        justify-content: center;
        flex-direction: column;
        grid-area: 2 / 1 / 3 / 3;
    }
    
    .second-section-title{
        width: 358px;
        /* margin-left: -9px; */
        font-size: 28px;
        display: flex;
        align-items: center;
    }
    
    .second-section-title-active{
        /* margin-left: -9px; */
        font-size: 28px;
        width: 358px;
        display: flex;
        align-items: center;
        color:#CEEF49 ;
    }
    
    .sections{
        display: flex;
        justify-content: center;
        /* align-items: flex-start; */
        flex-direction: column;
    }

    .event-agenda-t {
        font-family: 'Epilogue';
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 35px;
        margin-left: 10px;
    }

    .key-speakers-title{
        font-weight: 900;
        font-size: 29px;
        line-height: 35px;
        display: flex;
        align-items: center;
        letter-spacing: 0.12em;
        color: #00AEEF;
    }
    
    .border{
        height: 1px;
        border-top: 1px solid rgba(255, 255, 255, 0.25);
        display: block;
        position: relative;
        top: 1px;
        width: 100%;
    }
    
    /* .key-speakers-first{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        
    } */
    
    
    .first-speaker{    
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        grid-area: 1 / 1 / 2 / 2;
    }
    
    .second-speaker{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        grid-area: 2 / 1 / 3 / 2;
    }
    
    .third-speaker{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        grid-area: 3 / 1 / 4 / 2;
    }
    
    .fourth-speaker{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        grid-area: 4 / 1 / 5 / 2;
    }
    
    .fifth-speaker{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        grid-area: 5 / 1 / 6 / 2; 
    }
    
    .panel-speakers{
        font-weight: bolder;
        color: #00AEEF;
    }
    
    .event-content{
        /* line-height: 21px; */
        text-align: center;
        padding: 0px 15px 0px 15px;
    }
    
    
    
    .event-closing-details{
        display: flex;
        justify-content: center;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        opacity: 0.8;
    }
    
    .event-closing-activities{
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        font-weight: bold;
    
    }
    

}


@media only screen and (min-width: 481px) and (max-width: 820px) {
    .coming-soon-homepage{
        align-items: center;
        flex-direction: column;
        padding: 0px 34px;
        background-color: #010057;
        color: white;
        padding: 68px 38px;
        overflow: hidden;
    }
    
    .content-show{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px 0px;
        font-size: 15px;
        align-items: center;
    }
    
    .agenda-wrapper{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-family: 'Epilogue';
        font-style: normal;
    }

    .sections-event {
        /* display: flex; */
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 25px;
        margin-right: 0px;
    }
    
    .event-agenda-title{
        width: 100%;
        font-size: 30px;
        line-height: 35px;
      
    }

    .event-agenda-details-second {
        display: flex;
        align-items: center;
        letter-spacing: 0.12em;
        color: #FFFFFF;
        width: 109%;
        opacity: 0.8;
        margin-left: 0px;
    }
    
    
    .event-agenda-details{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width:max-content;
        
    }
    
    
    
    .event-agenda{
        margin-left: -9px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 35px;
        grid-area: 1 / 1 / 2 / 2;
    }
    
    .first-section-event{
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 26px;
        grid-area: 1 / 1 / 3 / 2; 
    }
    
    .first-section-title{
        margin-left: -9px;
        display: flex;
        align-items: center;
    }
    
    .first-section-title-active{
        margin-left: -9px;
        display: flex;
        align-items: center;
        color: #CEEF49;
    }
    
    .second-section-event{
        display: flex;
        justify-content: center;
        flex-direction: column;
        grid-area: 2 / 1 / 3 / 3;
    }
    
    .second-section-title{
        margin-left: -9px;
        display: flex;
        align-items: center;
    }
    
    .second-section-title-active{
        margin-left: -9px;
        display: flex;
        align-items: center;
        color:#CEEF49 ;
    }
    
    .sections{
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .key-speakers-title{
        font-weight: 900;
        font-size: 29px;
        line-height: 35px;
        display: flex;
        align-items: center;
        letter-spacing: 0.12em;
        color: #00AEEF;
    }
    
    .border{
        height: 1px;
        border-top: 1px solid rgba(255, 255, 255, 0.25);
        display: block;
        position: relative;
        top: 1px;
        width: 100%;
    }
    
    /* .key-speakers-first{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        
    } */
    
    
    .first-speaker{    
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        grid-area: 1 / 1 / 2 / 2;
    }
    
    .second-speaker{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        grid-area: 2 / 1 / 3 / 2;
    }
    
    .third-speaker{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        grid-area: 3 / 1 / 4 / 2;
    }
    
    .fourth-speaker{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        grid-area: 4 / 1 / 5 / 2;
    }
    
    .fifth-speaker{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        grid-area: 5 / 1 / 6 / 2; 
    }
    
    .panel-speakers{
        font-weight: bolder;
        color: #00AEEF;
    }
    
    .event-content{
        line-height: 21px;
        text-align: center;
        padding: 0px 15px 0px 15px;
    }
    
    
    
    .event-closing-details{
        display: flex;
        justify-content: center;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        opacity: 0.8;
    }
    
    .event-closing-activities{
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        font-weight: bold;
    
    }
    

}

@media only screen and (min-width: 821px) and (max-width: 1024px) {
    .coming-soon-homepage {
        padding: 45px 76px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .coming-soon-homepage {
        padding: 45px 80px;
    
    }
  }
  
  @media only screen and (min-width: 1201px) and (max-width: 1727px) {
    .coming-soon-homepage {
        padding: 45px 85px;
    
    }

    .sections-event {
        display: flex;
        /* align-items: baseline; */
        flex-direction: row;
        justify-content: space-between;
        /* justify-content: space-around; */
        margin-bottom: 25px;
        margin-right: 605px;
    }
  }
  
  
  @media only screen and (min-width: 1728px)  and (max-width:1920px) {
   
  
  
  }
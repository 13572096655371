  .card{
    background-color: #010057;
    overflow: hidden;
    color: white;
    background-color: rgba(24, 2, 2, 0.24);
    mix-blend-mode: normal;
    backdrop-filter: blur(8px);
    height: 65px;
    width: 99.5%;
  }

  .text {
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: center;
    text-decoration: none;
    padding: 1rem 1rem;
    height: 100%;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 900;
    font-size: 19px;
    line-height: 40px;
    font-weight: bold;
  }
  
  .text:hover {
    letter-spacing: initial;
    color: rgb(120, 121, 164);
  }


  .button{
    cursor: pointer;
  }

  
	@media only screen and (min-width:320px)and(max-width: 480px){
    .card{
      height: 50px;
    }

    .text {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.9rem 3rem;
      font-size: 24px;
    }
  }

  @media only screen and (min-width: 481px) and (max-width: 820px) {
    .text {
      display: flex;
      justify-content: center;
      color: #fff;
      align-items: center;
      text-decoration: none;
      padding: 0.5rem 1rem;
      height: 100%;
      font-family: 'Epilogue';
      font-style: normal;
      align-items: center;
      font-weight: 900;
      font-size: 21px;

      line-height: 40px;
  }

  .card{
    font-size: 19px;
    height: 50px;
  }
  }

  @media only screen and (min-width: 821px) and (max-width: 1024px) {
    
    .text {
      text-align: center;
      font-size: 24px;
      padding: 2.6rem 1rem;   
    }
  }
  
  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
   
    }
    
    @media only screen and (min-width: 1201px) and (max-width: 1727px) {
      .card{
        height: 130px;
      }
  
      .text {
        font-size: 24px;
        padding: 2.7rem 1rem;   
      }
    }
    
    
    @media only screen and (min-width: 1728px)  and (max-width:1920px) {
     .card{
      height: 120px;
     }


      .text {
        font-size: 24px;
        padding: 2.9rem 1rem;   
      }
    }

#home {
	flex-direction: column; 
	/* background-image: url(../assets/img/home.png); */
	background-color: #010057;
	background-size: cover;
	background-position: top center;
	position: relative;
	z-index: 0;
	display: flex;
}

.counter-details{
	margin-top: -30px;
	color: #fff;
	font-family: 'Epilogue';
	font-style: normal;
	font-weight: 900;
	font-size: 23px;
	line-height: 39px
}

.video{
	opacity: 0.7;
	width: 100%;

}
#home::after {
	display:flex;
	align-items:center;
	justify-content: center;
	flex-direction: column;
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color:#010057;
	opacity: 0.7;
	z-index: -1;
	
}


#home .home {
	/* padding: 144px 50px; */
	justify-content: flex-start;
	padding: 0px 0px;

}

.links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%; 
  }
  
  .links:hover {
    letter-spacing: initial;
    color: rgb(120, 121, 164);
  }

  .section-home-page{
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

  }

  .saveTheDate {
	display: flex;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	color: #E3FF75;
	font-weight: bold;
	margin: -15px 0px -51px 10px;
	display: flex;
	justify-content: center;
	width: 437px;
	height: 61px;
	top: 200px;
	font-size: 56px;
	line-height: 61px;
	opacity: 0.9;
	font-family: 'Epilogue';
	font-style: normal;

  }

  .youtube {
	margin-left: 63px;
	display: flex;
	justify-content: center;
	height: 28.84px;
	left: 807.2px;
	font-family: 'Epilogue';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	letter-spacing: 0.12em;
	color: #DE1E1C;
	cursor: pointer;
	border: none;
	background: none;
	text-decoration: none;
	margin-top: -6px;
  }


  .youtube:hover {
	color: #DE1E1C;
  }

  .youtube-icon{
	background-image: url(../assets/img/Youtube.svg) ;
	background-repeat: no-repeat;
	margin-left: 0px;
	padding-top: 10px;
  }

  .editions{
	display: flex;
	justify-content: center;
	margin-top: 24px;
	font-family: 'Epilogue';
	font-style: normal;
	font-weight: 900;
	font-size: 38px;
	line-height: 39px;
	color: #FFFFFF;
	opacity: 0.9;

  }

  .parent {
	margin-left: 96px;
	font-family: 'Epilogue';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 23px;
	color: #FFFFFF;
	opacity: 0.7;	
	}

	
	.location {
		grid-area: 1 / 1 / 2 / 2;
		margin-left: 3rem;
    	margin-top: 10px;
	}

	.locationicon{
		background-image: url(../assets/img/MapPinLine.svg) ;
		background-repeat: no-repeat; 
		margin-left: -180px;
	}
	
	.date { 
		grid-area: 2 / 1 / 3 / 2;
		margin-left: 3rem;
    	margin-top: 10px;	
	}

	.dateicon{
		background-image: url(../assets/img/Clock.svg) ;
		background-repeat: no-repeat; 
		margin-left: -180px;
	}

	.register {
	width: 108px ;
	height: 16px ;
	color: white;
	font-family: 'Epilogue';
	font-style: normal; 
	font-weight: 600 ;
	font-size: 16px ;
	line-height: 16px ;
	border: 2px solid rgba(255, 255, 255, 0.6) ;
	background-color: rgba(255, 255, 255, 0.13) ;
	border-radius: 100px ;
	cursor: pointer;
	width: 429px ;
	height: 44px ;
	margin-top: 40px;
	}


	/* --------- about section -------------*/
	.about-section{
		display: flex;
		justify-content: center;
		text-align: center;
		flex-direction: column;
		padding: 115px 140px;
	}

	.more-informations{
		display: flex;
		padding-left: 743px;
		height: 1rem; 
		/* padding-left: 849px; */
		padding-bottom: 3rem;
		padding-top: 3rem;
		font-size: 13px;
		line-height: 35px;
		color: #000000;
	}

	.about-informations{
		display: flex;
		justify-content: center;
		background-position: 1389px;
		background-image: url(../assets/img/About-informations.svg) ;
		background-repeat: no-repeat;
	}
	.about{
		font-weight: 600;
		font-size: 15px;
		line-height: 0px;
		text-align: left;
		letter-spacing: 0.2em;
		text-transform: uppercase;
		color: #343379;
	}

	
	.about-title{
	   font-weight: bolder;
		font-size: 49px;
		line-height: 60px;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		text-align: start;
		color: #010057;
	
	}
	
	.about-paragraph{
		position: relative;
		z-index: -1;
		margin-top:0px;
		font-weight: 400;
		font-size: 16px;
		line-height: 25px;
		text-align: justify;
		/* text-transform: lowercase; */
		color: #000000;
		opacity: 0.6;
		display: flex;
	}
	@media only screen and (min-width:320px) and (max-width: 480px)  {
		#home .home {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			padding: 0px 0px;	
		}

	
		#home::after {
			display:flex;
			align-items:center;
			justify-content: center;
			flex-direction: column;
			height: 100%;
			width: 100%;
			overflow: hidden;
		}
		
		.saveTheDate {
			font-weight: bolder;
			display: flex;
			align-items: center;
			justify-content: center;
			/* margin-left: -21px; */
			font-size: 40px;
			margin-top:10px;
			width: 350px;
		}

		.youtube {
			display: flex;
			justify-content: center;
			font-size: 16px;
			
		  }
		
		
		  .youtube-icon{
			display: flex;
			justify-content: center;
			align-items: center;	
			margin-top: 13px;
			margin-left: -12px;
		  }

		  .editions{
			font-weight: bolder;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 9px;
			font-size: 27.5px;
			margin-bottom: 14px;
			width: 360px;

		  }

		  .parent {
			display: flex;
			flex-direction: column;
			font-size: 16px;
			margin-top: 2px;
			}
		
			.location {
				margin-top: 1px;
				
			}

			.locationicon{
				margin-left: -165px;
			}
			
			.date { 
				margin-top: 1px;
			}
		
			.dateicon{
				margin-left: -165px;
				margin-top:10.5px;
			}

			
		
			.register {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 252px ;
				margin-top: 56px;
				margin-left: 4px;
				font-weight: bold;
				}

				.about-section {
					display: flex;
					justify-content: center;
					text-align: center;
					flex-direction: column;
					padding: 34px 27px;
			
				}

				.about-informations{
					display: flex;
					background-repeat: no-repeat;
					background-image: url(../assets/img/About-informations-copy.svg) ;
					background-position: 274px;
				}
				.more-informations{
				    display: flex;
					width: 53%;
					padding-left: 0px;
					padding-bottom: 1rem;
					padding-top: 0rem;
					font-size: 11px;
					line-height: 20px;
				}

				.about{
					font-size: 13px;
				}
				
				.about-title{
					display: flex;
					justify-content: center;
					align-content: center; 
					font-size: 26px;
					text-align: start;
					font-weight: bolder;
					line-height: 39px;
				 }
			
				 .about-paragraph{
					position: relative;
					font-weight: 400;
					font-size: 16px;
					
				}

				.section-home-page {
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;
				}

				.video {
					width: 100%;
				}
			
	}

	@media only screen and (min-width: 481px) and (max-width: 820px) {
		.about-section {
			padding: 34px 32px;
		}

		.about-title {
			font-size: 30px;		
			line-height: 39px
		}

		.more-informations {
			display: flex;
			background-position: 376px;
			padding-left: 1px;
		}

		#home::after {
			position: absolute;
			overflow: hidden;
		}
		.editions {
			font-size: 37px;
		}
	}

	
	@media only screen and (min-width: 821px) and (max-width: 1024px) {
		#home::after {
			position: absolute;
			overflow-y: hidden;
		}

		.more-informations {
			background-position: 740px;
			padding-left: 343px;
		}
		
		.about-section {
			padding: 34px 52px;
		}
		
		.about-title {
			font-size: 40px;
					
		}
	}


	@media only screen and (min-width: 1025px) and (max-width: 1200px) {
		.about-section {
			padding: 59px 54px;
		}

		.more-informations {
			background-position: 870px;
			padding-left:480px;
		}

		.about-title {
			font-size: 43px;
		}

	}

	@media only screen and (min-width: 1201px) and (max-width: 1727px) {
		.more-informations {
			background-position: 896px;
			padding-left: 85px;
			font-size: 14px;
			padding-top: 29px;

		}

		.about-informations {
			background-position: 926px;
		}

		.about-section {
			padding: 115px 100px;
		}


	}
	
	@media only screen and (min-width: 1728px)  and (max-width:1920px) {
		#home::after {
			position: absolute;
			overflow-x: hidden;
		}
		#home .home {

			padding: 0px 0px;
		}

		.more-informations {
			width: 16%;
			margin-top: 11px;
			line-height: 20px;
			/* padding-left: 797px; */
			font-size: 14px;
			margin-bottom: 24px;
			
		}

		.about-informations {
			background-position: 1303px;
		}
	}

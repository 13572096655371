@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


  .homepage {
    background-color: #010057;
    color: white;
    padding: 20px 159px;
    position: relative;  
    z-index: 1; 
    
} 

.paragraph{
  padding: 0px 0px 17px 0px;
}

  .homepage-th1{
    height: 56rem;
  }

  .homepage-th2{
    height: 46rem; 
} 

.homepage-th3{
  height: 65rem; 
} 

.homepage-th4{
  height: 44rem;
} 

.homepage-th5{
  height: 42rem; 
} 

.homepage-h1{
  height: 64rem;
} 

.homepage-h2{
  height: 45rem;   
} 

.homepage-r1{
  height: 71rem;
} 

.homepage-r2{
  height: 60rem; 
} 

.homepage-uxui{
  height: 54rem; 
} 

.title{
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 19px;
    color: #FFFFFF; 
}

.details-wrapper{
    display:  -webkit-inline-box;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    opacity: 0.7;
}

.thinktech-details {
    margin-top: 25px;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
}

.location-editions{
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    margin-left: 3rem;
    margin-top: 10px; 
}

.location-icon {
    background-image: url(../assets/img/Pin.svg) ;
      background-repeat: no-repeat;
      width: 100%;
}

.date-editions{
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    margin-left: 3rem;
    margin-top: 10px;
    width: 100%;
}

.date-icon{
    background-image: url(../assets/img/Calendar1.svg) ;
    background-repeat: no-repeat;
    margin-top: 2px;
    /* display: flex; */
}


 .slick-slide img {
    height: 100%;
    width: 130%;
    touch-action: auto;
    
}

 .activeSlide img {
    transform: scale(1.4);
    margin-left: auto; 
    margin-right: auto; 
}  
  
  .slide {
    margin: 0px -43px;
    transform: scale(0.7);
    transition: transform 300ms;
    opacity: 0.9;
  }
  
  .activeSlide {
    transform: scale(0.9);
    opacity: 1;
  }


  .model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #010057;
    transition: opacity .4s ease, visibility .4s ease,  transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow-y: hidden;

    z-index: 999;
    margin:0;
    
  }

  .model.open{
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    overflow: hidden;

  }

  .model img{
    object-fit: contain;
    width: auto;
    max-width: 100%;
    max-height: 86vh;
    box-sizing: border-box;
    margin: 0 auto;
    overflow: hidden;
    background-color: #010057;
  }

  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    /* right: calc(22% - 70px);
    top: calc(90vh - 74vh - 24px); */
    line-height: 20px;
    text-align: center;
    font-size: 38px;
    color: #ffffff;
  }

  .img-container{
    position: relative;
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
    
  .close-icon-uxui{
    position: absolute;
    color: #010057;
    right: 16px;
    top: 20px;
    font-size: 30px;
    cursor: pointer;
  }
  
      
  .close-icon-th1{
    position: absolute;
    right: 16px;
    
    font-size: 30px;
    cursor: pointer;
  }
  @media only screen and (min-width:320px) and (max-width: 480px){
    .homepage{
      display: flex;
      justify-content: center;
      flex-direction: column;
      overflow: hidden;
      padding: 0px 22px;
  } 

  .homepage-th1{
    display: flex;
    padding: 6px 22px;
    height: 90%;
  }

  .homepage-th2{
    display: flex;
    padding: 6px 22px;
    height: 90%;
} 

.homepage-th3{
  display: flex;
  padding: 1px 22px;
    height: 90%;
} 

.homepage-th4{
  display: flex;
  padding: 1px 22px;
    height: 90%;
} 

.homepage-th5{
  display: flex;
  padding: 5px 22px;
    height: 90%; 
} 

.homepage-h1{
  display: flex;
  padding: 1px 22px;
    height: 90%;
} 

.homepage-h2{
  display: flex;
  padding: 1px 22px;
    height: 90%; 
} 

.homepage-r1{
  display: flex;
  padding: 1px 22px;
    height: 90%;
} 

.homepage-r2{
  display: flex;
  padding: 1px 22px;
    height: 90%;
} 

.homepage-uxui{
  display: flex;
  padding: 1px 22px;
    height: 90%; 
} 
  
  .title{
      font-size: 31px;
      line-height: 35px;
     

  }
  
  .details-wrapper{
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;  
  }
  
  .thinktech-details {  
      margin-top: 7px;
      font-size: 18px;
      line-height: 28px;
  }
  
  .location-editions{
      font-size: 18px;
      line-height: 23px;
      margin-left: 3rem;
      margin-top: 10px;  
  }

  .date-editions{
      font-size: 18px;
      line-height: 23px;
      margin-left: 3rem;
      margin-top: 10px;
  }
  
   .slick-slide img {
      height: 100%;
      width: 300%;
      touch-action: auto;
  }
  
   /* .activeSlide img {
      transform: scale(1.4);
   
  }   */
    
    .slide {
      transform: scale(0.3);
      transition: transform 200ms;
      opacity: 0.9;
          margin: -233px -115px;
    }
    
    /* .activeSlide {
      transform: scale(1);
      opacity: 1;
      margin: 20px 0px 20px -120px;
    } */
  
  
    .model {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #010057;
      transition: opacity .4s ease, visibility .4s ease,  transform .5s ease-in-out;
      visibility: hidden;
      
      transform: scale(0);
      overflow: hidden;
      z-index: 999;
    }
  
    /* .ceneter{
      margin-right:100px;
    } */
    .model.open{
      position: fixed;
      visibility: visible;
      height: 109vh;
      opacity: 1;
      transform: scale(1); 
    }
  
    .model img{
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      box-sizing: border-box;
      padding: 20px 0 20px;
      margin: 0 auto;
    }
  
    .close-icon-th1{
      position: absolute;
      cursor: pointer;
      right: 21px;
      top: 40px;
      font-size: 30px;
    }
    
    .close-icon-th2{
      right: 15px;
      top: 31px;
      position: absolute;
      cursor: pointer;
      font-size: 30px; 
    }
    
    .close-icon-th3{
      position: absolute;
      cursor: pointer;
      right: 12px;
      top: 26px;
      font-size: 30px;
    }
    
    .close-icon-th4{
      right: 29px;
      top: 30px;
      font-size: 30px;
      position: absolute;
      cursor: pointer;
    }
    
    .close-icon-th5{
      position: absolute;
      cursor: pointer;
      right: 20px;
      top: 30px;
      font-size: 30px;
    }
    
    .close-icon-r1{
      position: absolute;
      cursor: pointer;
      color: #010057;
      right: 8px;
      top: 29px;
      font-size: 30px;
    }
    
    .close-icon-r2{
      right: 22px;
      top: 26px;
      font-size: 30px;
      position: absolute;
      cursor: pointer;
    }
    
    .close-icon-h1{
      position: absolute;
      cursor: pointer;
      right: 20px;
      top: 31px;
      font-size: 30px;
    }
    
    .close-icon-h2{
      position: absolute;
      cursor: pointer;
      right: 30px;
      top: 33px;
      font-size: 30px;
    }
    
    .close-icon-uxui{
      font-size: 30px;
    }
  
    
  }

 

   @media only screen and (min-width: 481px) and (max-width: 820px) {
    .details-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-weight: 600;
      
      font-size: 18px;
      line-height: 20px;
  }
    .homepage {
      background-color: #010057;
      color: white;
      padding: 20px 19px;
      position: relative;
      z-index: 1;
      height: 100%;
  }


  .close-icon-th1{
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
    font-size: 40px; 
  }
  
  .close-icon-th2{
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
    font-size: 40px; 
  }
  
  .close-icon-th3{
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
    font-size: 40px; 
  }
  
  .close-icon-th4{
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
    font-size: 40px; 
  }
  
  .close-icon-th5{
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
    font-size: 40px; 
  }
  
  .close-icon-r1{
    color: #010057;
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
    font-size: 40px; 
  }
  
  .close-icon-r2{
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 19px;
    font-size: 40px; 
  }
  
  .close-icon-h1{
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
    font-size: 40px; 
  }
  
  .close-icon-h2{
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
    font-size: 40px; 
  }
  
  .close-icon-uxui{
    font-size: 40px; 
  }
   }

   @media only screen and (min-width: 821px) and (max-width: 1024px) {
    .details-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
  }

  .homepage {
    padding: 48px;
    height: 100%;
}


.close-icon-th1{
  position: absolute;
  cursor: pointer;
  display: flex;
  right: 21px;
  top: 18px;
  font-size: 50px; 
}

.close-icon-th2{
  position: absolute;
  cursor: pointer;
  display: flex;
  right: 20px;
  top: 22px;
  font-size: 50px; 
}

.close-icon-th3{
  position: absolute;
  cursor: pointer;
  display: flex;
  right: 20px;
  top: 23px;
  font-size: 50px; 
}

.close-icon-th4{
  position: absolute;
  cursor: pointer;
  right: 20px;
  display: flex;
  top: 30px;
  font-size: 50px; 
}

.close-icon-th5{
  position: absolute;
  cursor: pointer;
  right: 20px;
  display: flex;
  top: 30px;
  font-size: 50px; 
}

.close-icon-r1{
  position: absolute;
  cursor: pointer;
  color: #010057;
  display: flex;
  right: 20px;
  top: 20px;
  font-size: 50px; 
}

.close-icon-r2{
  position: absolute;
  cursor: pointer;
  right:20px;
  top: 20px;
  display: flex;
  font-size: 50px; 
}

.close-icon-h1{
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 20px;
  display: flex;
  font-size: 50px; 
}

.close-icon-h2{
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 20px;
  display: flex;
  font-size: 50px; 
}

.close-icon-uxui{
  position: absolute;
  cursor: pointer;
  /* color: #010057;
  right: 28px;
  top: 241px;
  display: flex; */
  font-size: 50px; 
}

  }
  
  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
      .details-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
      }
 
      .close-icon-th1{
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 20px;
        font-size: 50px; 
      }
    
      .close-icon-th2{
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 20px;
        font-size: 50px; 
      }

      .close-icon-th3{
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 20px;
        font-size: 50px; 
      }

      .close-icon-th4{
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 20px;
        font-size: 50px; 
      }

      .close-icon-th5{
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 20px;
        font-size: 50px; 
      }

      .close-icon-r1{
        position: absolute;
        cursor: pointer;
        color: #010057;
        right: 20px;
        top: 20px;
        font-size: 50px; 
      }

      .close-icon-r2{
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 20px;
        font-size: 50px; 
      }
    
      .close-icon-h1{
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 20px;
        font-size: 50px; 
      }

      .close-icon-h2{
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 20px;
        font-size: 50px; 
      }

      .close-icon-uxui{
        position: absolute;
        cursor: pointer;
        font-size: 50px; 
      }

      .homepage {
        padding: 2px 46px;
        height: 100%;
      }
    }
    
    @media only screen and (min-width: 1201px) and (max-width: 1727px) {
      .close-icon-th1{
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 20px;
        font-size: 50px; 
      }
    
      .close-icon-th2{
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 20px;
        font-size: 50px; 
      }

      .close-icon-th3{
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 20px;
        font-size: 50px; 
      }

      .close-icon-th4{
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 20px;
        font-size: 50px; 
      }

      .close-icon-th5{
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 20px;
        font-size: 50px; 
      }

      .close-icon-r1{
        position: absolute;
        cursor: pointer;
        color: #010057;
        right: 20px;
        top: 20px;
        font-size: 50px; 
      }

      .close-icon-r2{
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 20px;
        font-size: 50px; 
      }
    
      .close-icon-h1{
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 20px;
        font-size: 50px; 
      }

      .close-icon-h2{
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 20px;
        font-size: 50px; 
      }

      .close-icon-uxui{
        position: absolute;
        cursor: pointer;
        /* color: #010057;
        right:120px;
        top: 111px; */
        font-size: 50px; 
      }

      .homepage {
        padding: 20px 44px;
        height: 100%;
    }

    }
    
    
    @media only screen and (min-width: 1728px)  and (max-width:1920px) {
      .close-icon-th1{
        position: absolute;
        right: 46px;
        top: 39px;
        font-size: 50px; 
        cursor: pointer;
      }
    
      .close-icon-th2{
        position: absolute;
        right: 46px;
        top: 52px;
        font-size: 50px; 
        cursor: pointer;
      }

      .close-icon-th3{
        position: absolute;
        right: 48px;
        top: 53px;
        font-size: 50px; 
        cursor: pointer;
      }

      .close-icon-th4{
        position: absolute;
        right: 50px;
        top: 50px;
        font-size: 50px; 
        cursor: pointer;
      }

      .close-icon-th5{
        position: absolute;
        right: 50px;
        top: 50px;
        font-size: 50px; 
        cursor: pointer;
      }

      .close-icon-r1{
        position: absolute;
        color: #010057;
        right: 30px;
        top: 30px;
        font-size: 50px; 
        cursor: pointer;
      }

      .close-icon-r2{
        position: absolute;
        right: 50px;
        top: 50px;
        font-size: 50px; 
        cursor: pointer;
      }
    
      .close-icon-h1{
        position: absolute;
        right: 30px;
        top: 50px;
        font-size: 50px; 
        cursor: pointer;
      }

      .close-icon-h2{
        position: absolute;
        right: 40px;
        top: 50px;
        font-size: 50px; 
        cursor: pointer;
      }

      .close-icon-uxui{
        font-size: 50px; 
      }



      
  .homepage {
    padding: 20px 129px;
} 
    }



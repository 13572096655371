  .logo{
    width: 176px;
    height: 31px;
    left: 190px;
    top: 92px;
  }

  .navbar {
    background-color: #010057;
    transition: 0.9s ease background-color; 
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    top: 0;
    z-index: 999;
    position: relative;
    width: 100%;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    text-align: right;
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 87%;
  }
  
  .nav-links:hover {
    letter-spacing: initial;
    color: rgb(120, 121, 164);
  }
  
  .fa-bars {
    color: #fff;
  }
  
    .nav-link-desktop{
      width: 108px;
      height: 16px;
      font-family: 'Epilogue';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      width: 176.71px;
      height: 46.45px;
      color: rgb(253, 253, 255);
      border: 2px solid rgba(255, 255, 255, 0.6);
      background: rgba(255, 255, 255, 0.13);
      border-radius: 100px;
      cursor: pointer;
    }
  
    .nav-link-desktop:hover {
      background-color: #f6f1ee;
      color:  #010057;
    }

  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }

  .subscribeText{
    padding-top: 26px;
    height: 44px;
    font-family: 'Epilogue';
    font-weight: 900;
    font-size: 37px;
    line-height: 16px;
    color: #010057;
  }

  .sub-text {
  color: #010057;
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  }

 
  @media screen and (min-width:320px) and (max-width: 480px) {
    .NavbarItems {
      display: flex;
    }



    .regsiter-popup {
      position: fixed;
      /* overflow: hidden; */
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-width: 100%;
      max-height: 122%;
      border-radius: 10px;
      top: 0;
      left: 0;
      z-index: 1;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 98%;
      height: 103vh;
      position: absolute;
      top: -46px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #010057;
      left: auto;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      width: 360px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      z-index: 2;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }

    .nav-item {
      height: 80px;
    }
  
    .nav-links-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: 'Epilogue';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      width: 176.71px;
      height: 46.45px;
      color: rgb(253, 253, 255);
      border: 2px solid rgba(255, 255, 255, 0.6);
      background: rgba(255, 255, 255, 0.13);
      border-radius: 100px;
      cursor: pointer;
      margin-top: 17px;
    }
  
    .nav-links-mobile:hover {
      background-color: #f6f1ee;
      color:  #010057;
    }

    .nav-link-desktop{
      display: none;
    }

    .subscribeText{
      padding-top: 26px;
      height: 44px;
      font-family: 'Epilogue';
      font-weight: 900;
      font-size: 37px;
      line-height: 16px;
      color: #010057;
    }
  
    .sub-text {
    color: #010057;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    }  
  }
  
  @media only screen and (min-width: 481px) and (max-width: 820px){

    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 104vh;
      position: absolute;
      overflow: hidden;
      top: -25px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
    .nav-menu.active {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #010057;
      left: auto;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }

    .nav-links {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

  
    .menu-icon {
      display: block;
      position:absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      z-index: 2;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }

  
  
    .nav-links-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: 'Epilogue';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      width: 176.71px;
      height: 46.45px;
      color: rgb(253, 253, 255);
      border: 2px solid rgba(255, 255, 255, 0.6);
      background: rgba(255, 255, 255, 0.13);
      border-radius: 100px;
      cursor: pointer;
      margin-top: 17px;
    }
  
    .nav-links-mobile:hover {
      background-color: #f6f1ee;
      color:  #010057;
    }

    .nav-link-desktop{
      display: none;
    }

  }




  @media only screen and (min-width: 821px) and (max-width: 1024px) {
  
  .nav-links-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    width: 176.71px;
    height: 46.45px;
    color: rgb(253, 253, 255);
    border: 2px solid rgba(255, 255, 255, 0.6);
    background: rgba(255, 255, 255, 0.13);
    border-radius: 100px;
    cursor: pointer;
    margin-top: 17px;
  }

  .nav-links-mobile:hover {
    background-color: #f6f1ee;
    color:  #010057;
  }

  .nav-link-desktop{
    display: none;
  }
.nav-menu {
  width: 65vw;
}

.homepage {
  padding: 20px 99px;
}
  }


  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .nav-menu {
      width: 56vw;
    }

    
  }

 
  
  @media  only screen and (min-width: 1728px)  and (max-width:1920px){
    .subscribeText {
      padding-top: 7px;
     margin-left: 10px;
  }
  }
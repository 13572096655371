@font-face {
  font-family: "Epilogue";
  src: url("../src/assets/fonts/Epilogue-Regular.ttf");
  }

body {
  margin: 0;
  font-family: 'Epilogue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Epilogue',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

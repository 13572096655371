.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 120vh;
    top: 0;
    left: 0;
  }
  
  .box {
    position: relative;
    top: 10%;
    width: 454px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    height: 25%;
    max-height: 70vh;
    margin-top: 99px;
    background: #fff;
     border-radius: 10px;
    padding: 20px;
    border: 1px solid #999;
    overflow: hidden;
  }

    .email__input {
      margin-right: 105px;
    margin-top: 20px;
    padding-left: 20px;
    width: 335px;
    height: 52px;
    background: #F8F8F8;
    border-radius: 100px ;
    border:0;outline:0;
  } ::placeholder {
    color: #010057;
  }

  
  .close-icon-popup {
    content: 'x';
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 25px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    font-size: 32px;
    color: #010057;
  }

  .handling-error-email{
    display: flex;
    margin-top: 10pxc;
  }

  .error-email{
    color: #8B0000;
  font-size: 12px;
  margin-top:10px;
  margin-left: 20px;
  }

  .subscribe {
    display: flex;
    height: 30px;
    cursor: pointer;
    color: #fff;
    background-color: #010057;
    transform: translateX(-79px);
    width: 69px;
    height: 57px;
    border-radius: 30px 5px 30px 30px;
    background-image: url(../../assets/img/Vector.png) ;
    background-repeat: no-repeat;
    border-color: #010057;
    background-position: center;
    margin-left:285px;
    transform: translateY(-53px);

} 


  @media only screen and (min-width: 320px) and (max-width:480px){
    .box {
      width: 359px;
    }
    .email__input {
      margin-right: 55px;
      width: 282px;  
    }

    .subscribe {
      margin-left: 239px;
      transform: translateY(-56px);
  }
  }


 

  @media only screen and (min-width: 821px) and (max-width: 1024px) {
    .close-icon-popup{
      right: 25px;
      top: 25px;
    }
  }

  
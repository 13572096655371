.regsiter-popup {
    display: flex;
    position:fixed;
    background: #00000050;
    width: 100%;
    height: 106vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000 !important;
    justify-content: center;
  }
  
  .register-box {
    top: 10%;
    max-height: 72%;
    position: relative;
    width: 560px;
    height: 80%;
    background: #fff;
    border-radius: 10px;
    padding: 26px 2px 0px 56px;
    border: 1px solid #999;
    overflow: auto;
    
  }
  

  .close-register {
    content: 'x';
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 24px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    text-align: center;
    font-size: 30px;
    color: #010057;
  }


  .register-button{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 135px;
    margin-bottom: 10px;
    margin-top: 29px;
    width: 226.55px;
    height: 52.56px;
    color:white;
    background: rgba(1, 0, 87, 0.8);
    border-radius: 100px;
    border: 2px  rgba(1, 0, 87, 0.8) ;
    cursor: pointer;
}

.registerForm{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
}

  form label {
    display: block;
  }

  
 .full-width-button {
    grid-column: 1 / 3;
  }
  
  .informations{
    height: 35px;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 31px;
    color: #010057;
  }

  .informations-lastname{
    height: 35px;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 31px;
    color: #010057;
  }


  .informations-email{
    height: 35px;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 31px;
    color: #010057;
  }

  .informations-phone{
    height: 35px;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 31px;
    color: #010057;
  }

  .informations-gender{
    height: 35px;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 31px;
    color: #010057;
  }

  
  .informations-question{
    height: 35px;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 31px;
    color: #010057;
  }
  .first-name{
    grid-area: 1 / 1 / 2 / 2;
  }

  .firstName{
    display: flex;
    padding-left:24px ;
    width: 213.55px;
    height: 52.56px;
    background: #F8F8F8;
    border-radius: 100px;
    border: 2px #F8F8F8;
  } ::placeholder {
    color: rgba(1, 0, 87, 0.5) !important;
  }

  .last-name{
    grid-area: 1 / 2 / 2 / 3;
  }

  .lastName{
    display: flex;
    padding-left:24px ;
    margin-left:15px ;
    width: 213.55px;
    height: 52.56px;
    background: #F8F8F8;
    border-radius: 100px;
    border: 2px #F8F8F8;
    }


  .message{
    width: 519.26px;
    height: 46.22px;
    left: 662.65px;
    top: 178.59px;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 60px;
    color: #010057;
  }


.details{ 
    width: 519.26px;
    height: 67.06px;
    top: 232.96px;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    color: rgba(1, 0, 87, 0.7);
}

.email-address {
    grid-area: 2 / 1 / 3 / 3;
}

.email {
  display: flex;
    padding-left:24px;
    width: 470px;
    height: 52.56px;
    border: 2px #F8F8F8;
    background: #F8F8F8;
    border-radius: 100px;
}

.phoneNumber{
    display: flex;
    padding-left:24px;
    width: 470px;
    height: 52.56px;
    border: 2px #F8F8F8;
    background: #F8F8F8;
    border-radius: 100px;
}

.phone-number { grid-area: 3 / 1 / 4 / 3;}


input:active,
input:focus {
   outline-color:rgba(1, 0, 87, 0.5);
   color: #010057;
}

.gender{
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 35px;
    color: #010057;
}


.question{
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 35px;
    margin-bottom: 0px;
    color: #010057;
  
}

.gender-checkbox{
  margin-left: 20px;
}


input[type=radio] {
  cursor: pointer;
  visibility: hidden;
  /* opacity: 0; */
}

input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -7px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -7px;
  position: relative;
  background-color:#010057;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
  
}

.input-error {
  border-color:#8B0000;
}

.error {
  color: #8B0000;
  font-size: 12px;

}

.gender-wrapper{
grid-area: 4 / 1 / 5 / 2; 
}

.error-lastname{
  color: #8B0000;
  font-size: 12px;
  margin-left: 21px;
}

.handling-error-gender{
  display: flex;
}

.handling-error-role{
  display: flex;
}

.question-wrapper{
  grid-area: 4 / 2 / 5 / 3;
}
.success-msg {
  color: white;
  background: #010057;
  display: inline-block;
  margin-left: 72px;
  text-align: center;
  padding: 0.5rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

@media only screen and (min-width:320px) and (max-width: 480px){
 
  .register-box {
    position: fixed;
    width: 407px;
    max-height: 113%;
    height: 87%;
    padding: 0px 0px 0px 0px;
    overflow-x: hidden;
    z-index: inherit;
  }
  
  .close-register {
    right: 24px;
    top: 24px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    text-align: center;
    font-size: 27px;
    color: #010057;
  }


  .register-button{
    margin-left: 105px;
    margin-bottom: 10px;
    margin-top: 18px;
    width: 226.55px;
    height: 52.56px;
    cursor: pointer;
}

.registerForm{
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

 .full-width {
    grid-column: 1 / 3;
  }
  
  .informations{
    height: 35px;
    margin-left: 34px;
    font-size: 14px;
    line-height: 31px;
  }

  .first-name{
    grid-area: 1 / 1 / 2 / 2;
  }

  .firstName{
    padding-left:24px ;
    margin: 0px 0px 0px 24px;
    width: 334.55px;
    height: 52.56px;
    left: 656.31px;
    top: 395.18px;
  } 

  .last-name{
    grid-area: 2 / 1 / 3 / 2;
  }

  .lastName{
    padding-left:24px ;
    margin: 0px 0px 0px 24px;
    width: 334.55px;
    height: 52.56px;
    }

  .message{
    font-weight: 900;
    font-size: 23px;
    line-height: 60px;
    margin-left: 27px;
  }

.details{ 
    margin: -15px 0px 0px 29px;
    width: 86%;
    font-size: 13px;
    line-height: 24px;
}

.email-address {
    grid-area: 3 / 1 / 4 / 2;
}

.email {
    padding-left:24px;
    margin: 0px 0px 0px 24px;
    width: 334.55px;
    height: 52.56px;
}

.phoneNumber{
    padding-left:24px;
    margin: 0px 0px 0px 24px;
    width: 334.55px;
    height: 52.56px;
}

.phone-number {
  margin-top: 14px;
  grid-area: 4 / 1 / 5 / 2;
}

.gender{
    margin-left:34px;
    font-size: 12px;
    line-height: 35px;
}


.question{
    margin-left:34px;
    font-size: 12px;
    line-height: 35px;
    margin-bottom: 0px;
    color: #010057;
  
}

.gender-checkbox{
  margin-left: 20px;
}

.input-error {
  border-color:#8B0000;
}

.error {
  color: #8B0000;
  font-size: 12px;
  margin-left: 30px;

}

.gender-wrapper{
  
  margin-top: 14px;
  grid-area: 5 / 1 / 6 / 2;
}

.error-lastname{
  color: #8B0000;
  font-size: 12px;
  margin-left: 30px;
}

.handling-error-gender{
  display: flex;
}

.handling-error-role{
  display: flex;
}

.question-wrapper{
  margin-top: 14px;
  grid-area: 6 / 1 / 7 / 2;
}

.informations-lastname{
  margin-top: -10px;
}

.informations-email{
  height: 35px;
  margin-left: 34px;
  font-size: 14px;
  line-height: 31px;
}

.informations-phone{
  height: 35px;
  margin-left: 34px;
  font-size: 14px;
  line-height: 31px;
}

.informations-gender{
  height: 35px;
  margin-left: 3px;
  height: 35px;
  font-size: 14px;
  line-height: 31px;
}

.informations-question{
  margin-left: 3px;
  height: 35px;
  font-size: 14px;
  line-height: 31px;
}
}


@media only screen and (min-width: 481px) and (max-width: 820px) {
  .registerForm{
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  
  .register-box {
    position: fixed;
    width: 407px;
    height: 87%;
    padding: 0px 0px 0px 0px;
    overflow-x: hidden;
    z-index: inherit;
  }
  

  .full-width {
    grid-column: 1 / 3;
  }
  
  
  .first-name{
    grid-area: 1 / 1 / 2 / 2;
  }

  .last-name{
    grid-area: 2 / 1 / 3 / 2;
  }

  .email-address {
    grid-area: 3 / 1 / 4 / 2;
}


.phone-number {
  margin-top: 14px;
  grid-area: 4 / 1 / 5 / 2;
}


.gender-wrapper{
  margin-top: 14px;
  grid-area: 5 / 1 / 6 / 2;
}

.question-wrapper{
  margin-top: 14px;
  grid-area: 6 / 1 / 7 / 2;
}

.message {
  font-size: 23px;
  margin-left: 27px;
}

.details {
  margin: -15px 0px 0px 29px;
  width: 86%;
  font-size: 13px;
  line-height: 24px;
}

.informations {
  height: 35px;
  margin-left: 34px;
  font-size: 14px;
  line-height: 31px;
}

.firstName {
  padding-left: 24px;
  margin: 0px 0px 0px 24px;
  width: 334.55px;
  height: 52.56px;
}

.informations-lastname {
  margin-top: -10px;
}

.lastName {
  padding-left: 24px;
  margin: 0px 0px 0px 24px;
  width: 334.55px;
  height: 52.56px;

}

.informations-email {
  height: 35px;
  margin-left: 34px;
  font-size: 14px;
}

.email {
  padding-left: 24px;
  margin: 0px 0px 0px 24px;
  width: 334.55px;
  height: 52.56px;
}

.informations-phone {
  height: 35px;
  margin-left: 34px;
  font-size: 14px;
}

.phoneNumber {
  padding-left: 24px;
  margin: 0px 0px 0px 24px;
  width: 334.55px;
  height: 52.56px;
}

.informations-gender {
  height: 35px;
  margin-left: 3px;
  height: 35px;
  font-size: 14px;
}

.gender {
  margin-left: 34px;
  font-size: 12px;

}

.informations-question {
  margin-left: 3px;
  height: 35px;
  font-size: 14px;

}

.question {
  margin-left: 34px;
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 35px;
  margin-bottom: 0px;
  color: #010057;
}


.error {
  font-size: 12px;
  margin-left: 30px;

}
}


@media only screen and (min-width: 821px) and (max-width: 1024px) {
  .register-box {
    width: 560px;
    height: 86%;
    padding: 26px 2px 0px 56px;
}



}

/* @media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .register-box {
    width: 53.5%;
}
}

@media only screen and (min-width: 1201px) and (max-width: 1727px) {
  .register-box {
    width: 560px;
}
} */


/* @media only screen and (min-width: 1728px)  and (max-width:1920px) {
  .register-box {
    max-height: 80%;
    width: 560px;
    height: 85%;
    padding: 26px 2px 0px 56px;

}
} */
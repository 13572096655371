*{
    font-family: 'Epilogue';
 
}


/* 
.about-page{
    height: 50rem;
    color: white;
    padding: 145px 230px;
} */



.about-title-t{
    font-weight: 600;
    font-size: 15px;
    line-height: 0px;
    text-align: left;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #343379;
}

.about-page-title{
    /* height: 50rem; */
    color: white;
    padding: 145px 230px;
}

.about-title-p{
   font-weight: bolder;
    font-size: 40px;
    line-height: 66px;
    color: #010057;

}

.about-paragraph-full{
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: justify;
    color: #000000;
    opacity: 0.6;
}

.about-ceo{
    color: #000000;
    opacity: 0.6; 
    
}

.ceo{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #010057;
}

@media only screen and (min-width:320px) and (max-width: 480px){

.about-title-t{
    font-size: 15px;
}

.about-page-title{
    color: white;
    align-items: center;
    flex-direction: column;
    margin: 67px auto;
    padding: 0px 34px;

}

.about-title-p{
   font-weight: bolder;
    font-size: 29px;
    line-height: 55px;
}

.about-paragraph-full{
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    text-align: justify;
}

.about-ceo{
    color: #000000;
    opacity: 0.6; 
    
}

.ceo{

    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #010057;
}
}

@media only screen and (min-width: 481px) and (max-width: 820px) {
    .about-page-title {
        padding: 25px 33px;
    }

    .about-title-p {
        font-size: 31px;
        line-height: 56px;

    }
}

@media only screen and (min-width: 821px) and (max-width: 1024px) {
    .about-page-title {
        padding: 58px 108px;
    }

    .about-title-p {
        font-size: 31px;
        line-height: 56px;

    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .about-page-title {
        padding: 23px 76px;
    }
  }
  
  @media only screen and (min-width: 1201px) and (max-width: 1727px) {
    .about-page-title {
        padding: 65px 103px;
    }
  }
  
  
  @media only screen and (min-width: 1728px)  and (max-width:1920px) {
    .about-page-title {
        
        color: white;
        padding: 66px 132px;
    }
  
  
  }

@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css');

.footer-container {
    background-color:white;
    padding: 3rem 7rem 2rem 9rem;
    
  }

  .footer-links-wrapper{
    display: flex;
    justify-content: space-between;
  }

  .footer-copyright{
    margin-right: 12px;
    padding: 0px 24px 0px 0px;
    display: flex;
    justify-content:space-between; 
  }

  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 21px;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }
  
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    box-sizing: border-box;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 50px;
    color: #000000;
  }
  
  .footer-organizer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    box-sizing: border-box;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #000000;
    margin-top: 10px;

  }

  .footer-link-items a {
    color: black;
    text-decoration: none;
   
  }

  .footer-organizer  a {
    color: black;
    text-decoration: none;
   
  }
  .footer-link-items a:hover {
    color: black;
    transition: 0.3s ease-out;
  }
  
 .footer-email{
  cursor: pointer;
    text-decoration: underline;
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
 }
  

.footer-copyright{
    display: flex;

}


.landmark-logo{
    margin: 10px 0px 10px 0px;
    width: 176px;
    left: 190px;
    top: 92px;
  
}
  
a, a:hover {
	text-decoration: none;
}

.socialbtns, .socialbtns ul, .socialbtns li {
    display: flex;
    justify-content: center;
    align-items:flex-start;
    padding: 10px ;
    flex-direction: row;
    /* justify-content: space-between; */
    /* margin-left: 2rem; */
    /* padding: 0.4rem; */
}

.socialbtns li {
    list-style: none outside none;
    display: inline-block;
}

.socialbtns .fa {
	width: 40px;
    height: 28px;
	color: #fff;
	background-color: #010057;
	border: 2px solid #010057;
	padding-top: 12px;
	border-radius: 25px;
	-moz-border-radius: 22px;
	-webkit-border-radius: 22px;
	-o-border-radius: 22px;
}

.socialbtns .fa:hover {
	color: #ffffff;
	background-color: #010057;
	border: 2px solid #010057;

}

  .website-rights {
    color: black;
    margin-bottom: 16px;
    margin-left: -49px;
  }
  
  
	@media only screen and (max-width: 480px){
    .footer-container {
      padding: 2.5rem 4rem 2rem 4.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  
    .footer-links-wrapper{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .footer-link-items {
      display: flex;
      width: 100%;
      flex-direction: column;
      font-weight: 400;
      font-size: 20px;
      line-height: 51px;
      
    }
    
  
    .footer-copyright{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  
    .footer-subscription-heading {
      line-height: 25px;
    }
  
    
    .footer-link-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    
    .footer-organizer {
      margin-top: 42px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    
   .footer-email{
      display: flex;
      font-size: 14px;
   }
    
  
  .footer-copyright{
      display: flex;
      justify-content: center;
      align-content: center;
      padding: 0px 0px 0px 0px;
      flex-direction: column;
  }
  
  
  .landmark-logo{
      width: 252px;
  }

  .landmark{
    margin-top: 10px;
  }
    
  
  .socialbtns, .socialbtns ul, .socialbtns li {
      display: flex;
  }
  

.socialbtns{
  display: flex;
  /* position: fixed; */
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}


.footer-organizer  a {
  color: black;
  margin-bottom: 17px;
  text-decoration: none;
 
}
  }

  
@media only screen and (min-width: 481px) and (max-width: 820px) {
  .footer-container {
    background-color: white;
    padding: 3rem 1rem 2rem 1rem;
}
}

@media only screen and (min-width: 821px) and (max-width: 1024px) {
  .footer-container {
    background-color: white;
    padding: 3rem 5rem 2rem 9rem;
}
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .footer-container {
    padding: 3rem 3rem 2rem 3rem;
}
}

@media only screen and (min-width: 1201px) and (max-width: 1727px) {
  /* .footer-container {
    padding: 3rem 3rem 2rem 3rem;
} */
}


@media only screen and (min-width: 1728px)  and (max-width:1920px) {
  


}
.countdown-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27rem;
    height: 5rem;
    font-weight: bold;
    font-size: 4.125rem;
    line-height: 2.5rem;
    opacity: 0.9;
    color:white;
    font-family: 'Epilogue';
    font-style: normal;
    margin-top: 5rem;
}

.countdown-timer span {
    margin-left: 0.313rem;
    margin-right: 0.313rem;
}

.two-numbers {
    width: 2ch;
}


@media only screen and (max-width: 480px){
    .countdown-timer{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 3.3rem;
        font-weight: bold;
        margin-top: 2.813rem;
        width: 360px;
        /* margin-left: -23px; */
    }
}